import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const Education = () => {
    return (
        <Layout>
            <Seo
                title="Educación - BIKESAFE"
                description="Hasta hace poco, caminar y las bicicletas para niños eran las formas preferidas por los estudiantes para ir al colegio.  Actualmente, los estudiantes van en autobús o les llevan en coche al colegio. En algunos colegios, facultades y universidades incluso se ha prohibido el uso de bicicletas y no disponen de parking para bicicletas. Da miedo pensar en ello.  ¿Cómo se supone que van a hacer los estudiantes el ejercicio físico que es tan necesario?"
            />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                            Educación
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <h3>Bicicleta para niños</h3>
                        <p className="flow-text">
                            Hasta hace poco, caminar y las bicicletas para niños eran las formas preferidas por los estudiantes para ir al colegio.  Actualmente, los estudiantes van en autobús o les llevan en coche al colegio. En algunos colegios, facultades y universidades incluso se ha prohibido el uso de bicicletas y no disponen de parking para bicicletas. Da miedo pensar en ello.  ¿Cómo se supone que van a hacer los estudiantes el ejercicio físico que es tan necesario?
                        </p>
                        <h3>Guía de parking para bicicletas de Bike Safe</h3>
                        <p className="flow-text">
                            En ocasiones, la planificación del parking para bicicletas es sencilla: comprar un rack para bicicletas. Otras veces, requiere algo más de planificación. Sea como sea, queremos que saque el mayor provecho de su dinero sin saltarse su presupuesto. Bike Safe trabaja con escuelas, facultades y universidades de todo el país para responder a las necesidades de parking para bicicletas. En Bike Safe, conocemos el valor de un parking para bicicletas de calidad y también sabemos que asesorar correctamente es a menudo igual de importante.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred" src="../images/new-york.jpg" className="shadow mt-5 mb-2 fluid rounded" />
                        <p>
                            <em className="flow-text">"Quiero expresar mi agradecimiento a todo el personal de Bike Safe por el servicio competente y profesional que prestan.  Fueron capaces de mostrar la flexibilidad y consideración necesarias para atender los requisitos de diseño de mi colegio".
                                <strong> Paul Jordan, director, escuela infantil Thames View, Dagenham</strong>.</em></p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center mt-5">
                        <h3>Identificar las necesidades y las áreas de conflicto</h3>
                        <p className="flow-text">
                            Conocer el uso actual que se hace de las bicicletas es un primer paso muy importante a la hora de establecer sus necesidades. Una manera sencilla de empezar es hacer un recuento de bicicletas en tres días normales en los que haga buen tiempo. Cuando haga recuento de las bicicletas tenga en cuenta lo siguiente:
                        </p>
                        <ul>
                            <li className="border p-2 shade-on-hover">¿Están aparcadas las bicicletas de forma que sean fácilmente accesibles?</li>
                            <li className="border p-2 shade-on-hover">¿Están aparcadas las bicicletas en espacios provistos al efecto y no candadas a árboles, postes, bancos, etc.?</li>
                        </ul>
                    </Col>
                    <Col sm="12" className="text-center">
                        <h3>Elija las ubicaciones apropiadas</h3>
                        <p className="flow-text">
                            Los racks para bicicletas normalmente están relegados a la parte trasera de un edificio o escondidos en el lateral, fuera de la vista. Esto no fomenta el uso de los racks además de no resultar cómodo a los ciclistas. Los racks colocados fuera de la vista de los peatones permite a los ladrones disponer de más tiempo y privacidad para robar. Los racks para bicicletas se deben colocar junto a la entrada principal, lo que no solo resulta cómodo para los usuarios de bicicletas, sino que también están en una zona visible. Una visibilidad superior no solo evita los robos sino que mejora el perfil de su programa de uso de bicicletas.
                        </p>
                        <h3>Piense en soluciones exclusivas</h3>
                        <p className="flow-text">
                            Una de las principales razones por las que los estudiantes no montan en bicicleta es que no se ofrece una protección adecuada para sus bicicletas. Los techos y aparcamientos cerrados para bicicletas ofrecen un nivel superior de protección a las bicicletas que se estacionan en exteriores frente a los elementos, en comparación con los racks que no están cubiertos.  Los techos evitan que la lluvia y la nieve caiga sobre las bicicletas y los usuarios, y los aparcamientos cerrados pueden proporcionar seguridad añadida.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred" src="../images/verticals.jpg" className="shadow mt-5 mb-2 fluid rounded" />
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center mt-5">
                        <h3>Preocupaciones de seguridad</h3>
                        <p className="flow-text">
                            Los diferentes tipos de racks para bicicletas y de métodos de instalación ofrecen distintos niveles de seguridad. Cuando elija un rack para su colegio, facultad o universidad, tal vez desee analizar las siguientes cuestiones:
                        </p>
                        <ul>
                            <li className="border p-2 shade-on-hover">¿Qué grado de importancia tiene la seguridad? ¿Constituyen los robos un problema en su colegio?</li>
                            <li className="border p-2 shade-on-hover">¿Cuántas bicicletas necesita aparcar y de cuánto espacio dispone?</li>
                            <li className="border p-2 shade-on-hover">¿Dónde se colocará el rack? ¿Asfalto, hierba, adoquines, hormigón?</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default Education;